import type { NextPage } from 'next'

const Home: NextPage = () => {
  return (
    <div className='flex justify-center items-center h-full'>
      <img src={'/assets/images/bg_img.svg'} alt='background image' className="fixed top-[-250px] lg:top-[-450px] -right-10"/>

      <h1 className="heading-1">Welcome to Lynxtool survey application</h1>

    </div>
  )
}

export default Home
